.error {
  background-color: #ffffff;
  width: 640px;
  margin-top: 15%;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 42px 36px;

  h1 {
    font-size: 22px;
    font-weight: 700;
    color: #1d2129;
    padding: 0 0 17px;
    margin: 0 0 29px;
    border-bottom: 1px solid #dddfe2;
  }

  span {
    color: #65676b;
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .error {
    width: 100%;
  }
}
