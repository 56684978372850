.modal-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;

  .modal-overlay-bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: 0.62;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .modal {
    position: relative;
    align-self: center;
    background-color: white;
    opacity: 1;
    z-index: 4;
    max-width: 335px;
    width: 335px;

    &--inner {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      padding: 30px;
    }

    .modal-close {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
      display: inline-block;
      width: 24px;
      height: 24px;
      background-position: top left;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='prefix__a' d='M11.95.15c6.55 0 11.9 5.3 11.9 11.85s-5.4 11.85-11.9 11.85S.1 18.5.1 12 5.4.15 11.95.15zm0 3.05c-4.85 0-8.8 4-8.8 8.85s3.95 8.8 8.8 8.8c4.85 0 8.8-4 8.8-8.85s-3.95-8.8-8.8-8.8zM15 6.7l2.25 2.25L14.2 12l3.05 3.1L15 17.35l-3.05-3.05-3.05 3.05-2.25-2.25L9.7 12 6.65 8.95 8.9 6.7l3.05 3.05L15 6.7z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='prefix__b' fill='%23fff'%3E%3Cuse xlink:href='%23prefix__a'/%3E%3C/mask%3E%3Cg fill='%23757575' mask='url(%23prefix__b)'%3E%3Cpath d='M0 0H24V24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='prefix__a' d='M11.95.15c6.55 0 11.9 5.3 11.9 11.85s-5.4 11.85-11.9 11.85S.1 18.5.1 12 5.4.15 11.95.15zm0 3.05c-4.85 0-8.8 4-8.8 8.85s3.95 8.8 8.8 8.8c4.85 0 8.8-4 8.8-8.85s-3.95-8.8-8.8-8.8zM15 6.7l2.25 2.25L14.2 12l3.05 3.1L15 17.35l-3.05-3.05-3.05 3.05-2.25-2.25L9.7 12 6.65 8.95 8.9 6.7l3.05 3.05L15 6.7z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='prefix__b' fill='%23fff'%3E%3Cuse xlink:href='%23prefix__a'/%3E%3C/mask%3E%3Cg fill='%231434CB' mask='url(%23prefix__b)'%3E%3Cpath d='M0 0H24V24H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }

    h2 {
      font-size: 16px;
      text-align: center;
    }

    p {
      margin-bottom: 30px;
    }

    .btn {
      width: 100%;

      &--primary {
        margin-bottom: 15px;
        height: 45px;
        display: flex;
        align-items: center;
      }

      &--secondary{
        height: 45px;
      }
    }
  }
}
