body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-rendering: geometricPrecision;
  color: #1c1d1d;
  background-color: #fff;
  /* Firefox */
  display: -moz-box;
  -moz-box-pack: center;
  -moz-box-align: center;
  /* Safari and Chrome */
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  /* W3C */
  display: box;
  box-pack: center;
  box-align: center;
  margin: 0px;
  padding: 0px;
}

.block-centered {
  display: flex;
  justify-content: center;
}

.nav-back {
  color: #437be5;
  font-size: 15px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDcgMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjUgMUwxIDYuNUw2LjUgMTIiIHN0cm9rZT0iIzIxNkZEQiIvPgo8L3N2Zz4K');
  background-repeat: no-repeat;
  background-position: left 45%;
  padding-left: 13px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

@media (max-width: 700px) {
  body {
    background-color: white;
  }
}
