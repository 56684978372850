@import './providersLogos.scss';

.consent {
  background-color: #ffffff;
  width: 640px;
  margin-top: 15%;
  margin-bottom: 40px;
  position: relative;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));

  &--checkout {
    margin-top: 10px;
    border-radius: 30px;
    border: var(--Space-3xs, 2px) solid #DFDDDD;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &__top {
    margin-top: 15%;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__bottom {
    display: flex;
    position: absolute;
    bottom: 0;
  }

  &__banner {
    width: 100%;
    height: 119px;
    box-sizing: border-box;
    background-color: #c9ccd1;
    display: flex;
    justify-content: center;

    &--nbid,
    &--nbid-mobile {
      background-color: #39134c;
      padding-top: 41px;
    }

    &--idin {
      background-color: rgb(0, 176, 240);
      padding-top: 25px;
    }

    &--ftn {
      background-color: #003580;
      padding-top: 41px;
    }

    &--mitid {
      background-color: #FFF;
      padding-top: 41px;
      border-bottom: 1px solid #437be5;
    }

    &--bootz {
      padding-top: 27px;
      background-color: #F5F5F5;
    }

    &--signicat {
      padding-top: 27px;
      background-color: #41FFD1;
    }

    &--checkout {
      display: none;
    }
  }

  &__data {
    box-sizing: border-box;
    margin: 27px 37px 0;

    &--content {
      ul {
        list-style-position: inside;
      }
    }

    &--description {
      font-size: 25px;
      text-align: center;
    }

    &--amount {
      margin: 30px 0;
      font-size: 48px;
      text-align: center;
      font-weight: 500;
    }

    ul {
      margin: 0px;
      padding: 0px;
      //list-style: none;

      li {
        color: #606770;
        line-height: 200%;

        //&::before {
        //  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQ4Njk0IDMuMDUyMjZMMy45MjYzOSA1LjQ5MTc5TDguNDEzMjkgMS4wMDQ4OCIgc3Ryb2tlPSIjNDM3QkU1IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
        //}
      }
      li span {
        position: relative;
        left: 10px;
      }
    }

    &--not-shared {
      ul {
        li {
          &::before {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOCA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuNTQ3NDggOC4zMTIwOEM2Ljg0MDM3IDguNjA0OTcgNy4zMTUyNSA4LjYwNDk3IDcuNjA4MTQgOC4zMTIwOEM3LjkwMTAzIDguMDE5MTggNy45MDEwMyA3LjU0NDMxIDcuNjA4MTQgNy4yNTE0Mkw1LjA1OTEgNC43MDIzN0w3LjYwODEgMi4xNTMzOEM3LjkwMDk5IDEuODYwNDggNy45MDA5OSAxLjM4NTYxIDcuNjA4MSAxLjA5MjcyQzcuMzE1MiAwLjc5OTgyNCA2Ljg0MDMzIDAuNzk5ODI0IDYuNTQ3NDQgMS4wOTI3MkwzLjk5ODQ0IDMuNjQxNzFMMS40NDk0NCAxLjA5MjcyQzEuMTU2NTUgMC43OTk4MjQgMC42ODE2NzUgMC43OTk4MjQgMC4zODg3ODIgMS4wOTI3MkMwLjA5NTg4ODYgMS4zODU2MSAwLjA5NTg4ODYgMS44NjA0OCAwLjM4ODc4MiAyLjE1MzM4TDIuOTM3NzggNC43MDIzN0wwLjM4ODczNyA3LjI1MTQyQzAuMDk1ODQ0MSA3LjU0NDMxIDAuMDk1ODQ0MSA4LjAxOTE4IDAuMzg4NzM3IDguMzEyMDhDMC42ODE2MyA4LjYwNDk3IDEuMTU2NSA4LjYwNDk3IDEuNDQ5NCA4LjMxMjA4TDMuOTk4NDQgNS43NjMwM0w2LjU0NzQ4IDguMzEyMDhaIiBmaWxsPSIjNjU2NzZCIi8+Cjwvc3ZnPgo=');
          }
        }
      }
      border-bottom: 1px solid #dddfe2;
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 23px 36px 27px;

    .btn:first-child {
      margin-right: 13px;
    }
  }

  .powered-by {
    font-size: 13px;
    color: #67788a;
    display: flex;
    margin: 12px 0 32px 36px;

    &--hidden {
      display: none;
    }
  }
}

.btn {
  box-sizing: border-box;
  padding: 8px 27px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  width: 144px;
  border: 1px solid #1434CB;

  &--text-only {
    color: #216fdb;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  &--secondary {
    color: #216fdb;
    background-color: transparent;

    &--checkout {
      border-radius: 6px;
      border: var(--Space-3xs, 2px) solid #186AFF;
    }

    &:hover {
      background-color: #3B57DE;
      color: #FFF;
    }
  }

  &--primary {
    background-color: #1434CB;
    color: #ffffff;
    display: flex;
    justify-content: center;
    padding: 8px 21px 8px 30px;

    &--checkout {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 6px;
      background: #186AFF;
      border: none;
    }

    &:hover {
      background-color: #3B57DE;
    }

    &:active {
      background-color: #1d3c78;
    }

    &:disabled {
      background-color: #aac9ff;
    }

    &-arrow {
      margin-left: 7px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDcgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNS41IDUuNUwxIDEwIiBzdHJva2U9IiNGQ0MwMTUiIHN0cm9rZS13aWR0aD0iMS41Ii8+Cjwvc3ZnPgo=")
    }
  }
}

@media (max-width: 700px) {
  .consent {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: 0;
    box-sizing: border-box;

    &__bottom {
      position: static;
      display: flex;
      border-top: 1px solid #dddfe2;
      box-sizing: border-box;
      padding-top: 8px;
      margin-top: 10px;
    }

    &__banner {
      border-radius: 0 0 10px 10px;
    }
  }

  .consent__data {
    border-bottom: none;
    margin: 27px 57px 0;

    &--amount {
      margin: 50px 0;
    }

    &--amount-checkout {
      background-color: #1c1d1d;
      margin: 100px 0;
    }
  }

  .consent__action {
    flex-direction: column;
    position: absolute;
    bottom: 100px;
    width: calc(100vw - 72px);
    padding: 21px 0 0 0;
    margin: 0px 36px 27px;

    .btn {
      width: 100%;

      &.btn--primary {
        margin-top: 20px;
        height: 50px;
        align-items: center;
      }

      &.btn--secondary {
        height: 50px;
      }
    }
  }

  .consent__bottom {
    .powered-by {
      display: flex;
      margin: 12px 36px 32px 36px;
      width: 100%;
      justify-content: space-between;
    }
  }
}
