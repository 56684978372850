.success-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .topbar {
    background-color: #FFF;
    height: 50px;
    width: 100vw;
  }

  .image-container {
    display: flex;
    width: 250px;
    height: 20px;
    padding-left: 20px;
    padding-top: 10px;
    justify-content: flex-start;
  }

  .payment-success {
    margin-top: 10%;
    width: 500px;
    border-radius: 12px;
    background-color: white;
    box-sizing: border-box;
    padding: 40px 40px 0px;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 30px;
    }

    .date {
      box-sizing: border-box;
      font-weight: 400;
      margin-bottom: 10px;
      display: inline-block;
      color: #8f9295;
    }

    .center-liner {
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    .company-name {
      font-weight: 500;
      font-size: 20px;
      display: inline-block;
      margin-bottom: 30px;
    }

    .txt-green {
      color: #20a61e;
    }

    .transaction-id {
      color: #8e9295;
      margin-bottom: 30px;
      display: inline-block;
    }
    .transaction-id-txt {
      color: black;
    }

    .price {
      font-size: 48px;
      background-color: #e4eaf4;
      box-sizing: border-box;
      padding: 20px 40px;
      border-radius: 100px;
      font-weight: 500;
      display: inline-block;
      margin-bottom: 30px;
    }

    .success-icon {
      margin-bottom: 30px;
    }
  }
}